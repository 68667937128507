import * as React from 'react';
import { Stack, Typography} from '@mui/material';

export default function Bar({msg}) {

    return (
        <>
            {/* <Stack direction="horizontal" sx={{ background:'rgb(0,0,0,0.78)', color:"#ffffff", justifyContent:'center', textAlign:'center', padding:'8px', borderBottom:'0px solid #e9e5d5'}}>
                    <Typography sx={{fontWeight:"normal", marginLeft:'5px',fontFamily:'Poppins !important', fontSize:'.95em'}}>  
                        <span style={{fontWeight:"bold", fontSize:"1.05em"}}>FREE SHIPPING</span> now available on orders of <b>$59</b> or more.
                    </Typography>
            </Stack>  */}
            <Stack direction="horizontal" sx={{background:'rgb(166,179,150,0.5)',  color:"#181818", justifyContent:'center', textAlign:'center', padding:'8px'}}>
                <Typography sx={{fontWeight:"normal", marginLeft:'5px',fontFamily:'Poppins !important', fontSize:'.95em'}}><span style={{fontWeight:'bold', fontSize:'15px'}}>VALENTINE'S DAY SALE</span><br /> Use code VALENTINE for 30% off of your order.</Typography>    
            </Stack>
       </>
    );
}
